import { Vector3 } from 'three';

export const navigationCoordinates = [
  [-56.77116988141385, 24.142499465495348, -5.66094515162907],
  [-56.596915961320775, 24.142499465495348, -7.901702628001474],
  [-45.3776681335293, 24.142499465495348, -7.856509201187821],
  [-45.23561918657848, 24.142499465495355, -2.8802532327434553]
];

export interface Segment<T = Vector3> {
  firstPoint?: PointType;
  lastPoint?: PointType;
  building?: string;
  storey?: string;
  customPointTypes?: {[key: number]: PointType};
  coordinates: T[];
  signature?: string;
  length?: number;
}

export type Coordinate = [number, number, number];
export type PointType = 'lift' | 'door' | 'building-door' | 'stairs';

export const paths: Segment<Coordinate>[] = 
[
{
  "coordinates": [
    [
      -55.79163406386275,
      32.753,
      -1.669130752666769
    ],
    [
      -56.70862547525994,
      32.753,
      -7.826066624402071
    ]
  ]
},
{
  "coordinates": [
    [
      -60.27833963229973,
      32.753,
      -9.889295478505417
    ],
    [
      -56.7413754493179,
      32.753,
      -10.053043807653795
    ],
    [
      -56.70862547525994,
      32.753,
      -7.826066624402071
    ]
  ]
},
{
  "coordinates": [
    [
      -56.70862547525994,
      32.753,
      -7.826066624402071
    ],
    [
      -45.082493612886395,
      32.753,
      -7.891565950450742
    ]
  ]
},
{
  "coordinates": [
    [
      -34.07860553505639,
      32.753,
      -7.53131965933696
    ],
    [
      -45.082493612886395,
      32.753,
      -7.891565950450742
    ]
  ]
},
{
  "lastPoint": "lift",
  "coordinates": [
    [
      -45.082493612886395,
      32.753,
      -7.891565950450742
    ],
    [
      -45.4754899879049,
      32.753,
      -3.8306080644412255
    ],
    [
      -48.291961132318804,
      32.753,
      -4.02710601126997
    ]
  ]
},
{
  "firstPoint": "lift",
  "lastPoint": "lift",
  "coordinates": [
    [
      -48.291961132318804,
      32.753,
      -4.02710601126997
    ],
    [
      -48.291961132318804,
      29.883,
      -4.02710601126997
    ]
  ]
},
{
  "coordinates": [
    [
      -55.79163406386275,
      29.883,
      -1.669130752666769
    ],
    [
      -56.70862547525994,
      29.883,
      -7.826066624402071
    ]
  ]
},
{
  "coordinates": [
    [
      -60.27833963229973,
      29.883,
      -9.889295478505417
    ],
    [
      -56.7413754493179,
      29.883,
      -10.053043807653795
    ],
    [
      -56.70862547525994,
      29.883,
      -7.826066624402071
    ]
  ]
},
{
  "coordinates": [
    [
      -56.70862547525994,
      29.883,
      -7.826066624402071
    ],
    [
      -45.082493612886395,
      29.883,
      -7.891565950450742
    ]
  ]
},
{
  "coordinates": [
    [
      -34.07860553505639,
      29.883,
      -7.53131965933696
    ],
    [
      -45.082493612886395,
      29.883,
      -7.891565950450742
    ]
  ]
},
{
  "lastPoint": "lift",
  "coordinates": [
    [
      -45.082493612886395,
      29.883,
      -7.891565950450742
    ],
    [
      -45.4754899879049,
      29.883,
      -3.8306080644412255
    ],
    [
      -48.291961132318804,
      29.883,
      -4.02710601126997
    ]
  ]
},
{
  "firstPoint": "lift",
  "lastPoint": "lift",
  "coordinates": [
    [
      -48.291961132318804,
      29.883,
      -4.02710601126997
    ],
    [
      -48.291961132318804,
      27.013,
      -4.02710601126997
    ]
  ]
},
{
  "coordinates": [
    [
      -64.09020736161592,
      27.013,
      -3.6531481234170284
    ],
    [
      -56.48591952175745,
      27.013,
      -3.6206511699856305
    ],
    [
      -56.70862547525994,
      27.013,
      -7.826066624402071
    ]
  ]
},
{
  "coordinates": [
    [
      -63.96022146569374,
      27.013,
      -10.12004271512032
    ],
    [
      -56.160950846997686,
      27.013,
      -10.022551829189574
    ],
    [
      -56.70862547525994,
      27.013,
      -7.826066624402071
    ]
  ]
},
{
  "coordinates": [
    [
      -56.70862547525994,
      27.013,
      -7.826066624402071
    ],
    [
      -45.082493612886395,
      27.013,
      -7.891565950450742
    ]
  ]
},
{
  "coordinates": [
    [
      -34.07860553505639,
      27.013,
      -7.53131965933696
    ],
    [
      -45.082493612886395,
      27.013,
      -7.891565950450742
    ]
  ]
},
{
  "lastPoint": "lift",
  "coordinates": [
    [
      -45.082493612886395,
      27.013,
      -7.891565950450742
    ],
    [
      -45.4754899879049,
      27.013,
      -3.8306080644412255
    ],
    [
      -48.291961132318804,
      27.013,
      -4.02710601126997
    ]
  ]
},
{
  "firstPoint": "lift",
  "lastPoint": "lift",
  "coordinates": [
    [
      -48.291961132318804,
      27.013,
      -4.02710601126997
    ],
    [
      -48.291961132318804,
      24.143,
      -4.02710601126997
    ]
  ]
},
{
  "coordinates": [
    [
      -64.09020736161592,
      24.143,
      -3.6531481234170284
    ],
    [
      -56.48591952175745,
      24.143,
      -3.6206511699856305
    ],
    [
      -56.70862547525994,
      24.143,
      -7.826066624402071
    ]
  ]
},
{
  "coordinates": [
    [
      -56.160950846997686,
      24.143,
      -10.022551829189574
    ],
    [
      -56.70862547525994,
      24.143,
      -7.826066624402071
    ]
  ]
},
{
  "coordinates": [
    [
      -56.70862547525994,
      24.143,
      -7.826066624402071
    ],
    [
      -45.082493612886395,
      24.143,
      -7.891565950450742
    ]
  ]
},
{
  "coordinates": [
    [
      -34.07860553505639,
      24.143,
      -7.53131965933696
    ],
    [
      -45.082493612886395,
      24.143,
      -7.891565950450742
    ]
  ]
},
{
  "lastPoint": "lift",
  "coordinates": [
    [
      -45.082493612886395,
      24.143,
      -7.891565950450742
    ],
    [
      -45.4754899879049,
      24.143,
      -3.8306080644412255
    ],
    [
      -48.291961132318804,
      24.143,
      -4.02710601126997
    ]
  ]
},
{
  "firstPoint": "lift",
  "lastPoint": "lift",
  "coordinates": [
    [
      -48.291961132318804,
      24.143,
      -4.02710601126997
    ],
    [
      -48.291961132318804,
      21.273,
      -4.02710601126997
    ]
  ]
},
{
  "coordinates": [
    [
      -64.09020736161592,
      21.273,
      -3.6531481234170284
    ],
    [
      -56.48591952175745,
      21.273,
      -3.6206511699856305
    ],
    [
      -56.70862547525994,
      21.273,
      -7.826066624402071
    ]
  ]
},
{
  "coordinates": [
    [
      -56.160950846997686,
      21.273,
      -10.022551829189574
    ],
    [
      -56.70862547525994,
      21.273,
      -7.826066624402071
    ]
  ]
},
{
  "coordinates": [
    [
      -56.70862547525994,
      21.273,
      -7.826066624402071
    ],
    [
      -45.082493612886395,
      21.273,
      -7.891565950450742
    ]
  ]
},
{
  "coordinates": [
    [
      -34.07860553505639,
      21.273,
      -7.53131965933696
    ],
    [
      -45.082493612886395,
      21.273,
      -7.891565950450742
    ]
  ]
},
{
  "lastPoint": "lift",
  "coordinates": [
    [
      -45.082493612886395,
      21.273,
      -7.891565950450742
    ],
    [
      -45.4754899879049,
      21.273,
      -3.8306080644412255
    ],
    [
      -48.291961132318804,
      21.273,
      -4.02710601126997
    ]
  ]
},
{
  "firstPoint": "lift",
  "lastPoint": "lift",
  "coordinates": [
    [
      -48.291961132318804,
      21.273,
      -4.02710601126997
    ],
    [
      -48.291961132318804,
      18.403,
      -4.02710601126997
    ]
  ]
},
{
  "coordinates": [
    [
      -55.79163406386275,
      18.403,
      -1.669130752666769
    ],
    [
      -56.70862547525994,
      18.403,
      -7.826066624402071
    ]
  ]
},
{
  "coordinates": [
    [
      -56.7413754493179,
      18.403,
      -10.053043807653795
    ],
    [
      -56.70862547525994,
      18.403,
      -7.826066624402071
    ]
  ]
},
{
  "coordinates": [
    [
      -56.70862547525994,
      18.403,
      -7.826066624402071
    ],
    [
      -45.082493612886395,
      18.403,
      -7.891565950450742
    ]
  ]
},
{
  "coordinates": [
    [
      -34.07860553505639,
      18.403,
      -7.53131965933696
    ],
    [
      -45.082493612886395,
      18.403,
      -7.891565950450742
    ]
  ]
},
{
  "lastPoint": "lift",
  "coordinates": [
    [
      -45.082493612886395,
      18.403,
      -7.891565950450742
    ],
    [
      -45.4754899879049,
      18.403,
      -3.8306080644412255
    ],
    [
      -48.291961132318804,
      18.403,
      -4.02710601126997
    ]
  ]
},
{
  "firstPoint": "lift",
  "lastPoint": "lift",
  "coordinates": [
    [
      -48.291961132318804,
      18.403,
      -4.02710601126997
    ],
    [
      -48.291961132318804,
      15.533,
      -4.02710601126997
    ]
  ]
},
{
  "coordinates": [
    [
      -64.09020736161592,
      15.533,
      -3.6531481234170284
    ],
    [
      -56.48591952175745,
      15.533,
      -3.6206511699856305
    ],
    [
      -56.70862547525994,
      15.533,
      -7.826066624402071
    ]
  ]
},
{
  "coordinates": [
    [
      -63.96022146569374,
      15.533,
      -10.12004271512032
    ],
    [
      -56.160950846997686,
      15.533,
      -10.022551829189574
    ],
    [
      -56.70862547525994,
      15.533,
      -7.826066624402071
    ]
  ]
},
{
  "coordinates": [
    [
      -56.70862547525994,
      15.533,
      -7.826066624402071
    ],
    [
      -45.082493612886395,
      15.533,
      -7.891565950450742
    ]
  ]
},
{
  "coordinates": [
    [
      -34.07860553505639,
      15.533,
      -7.53131965933696
    ],
    [
      -45.082493612886395,
      15.533,
      -7.891565950450742
    ]
  ]
},
{
  "lastPoint": "lift",
  "coordinates": [
    [
      -45.082493612886395,
      15.533,
      -7.891565950450742
    ],
    [
      -45.4754899879049,
      15.533,
      -3.8306080644412255
    ],
    [
      -48.291961132318804,
      15.533,
      -4.02710601126997
    ]
  ]
},
{
  "firstPoint": "lift",
  "lastPoint": "lift",
  "coordinates": [
    [
      -48.291961132318804,
      15.533,
      -4.02710601126997
    ],
    [
      -48.291961132318804,
      12.663,
      -4.02710601126997
    ]
  ]
},
{
  "coordinates": [
    [
      -64.09020736161592,
      12.663,
      -3.6531481234170284
    ],
    [
      -56.48591952175745,
      12.663,
      -3.6206511699856305
    ],
    [
      -56.70862547525994,
      12.663,
      -7.826066624402071
    ]
  ]
},
{
  "coordinates": [
    [
      -56.160950846997686,
      12.663,
      -10.022551829189574
    ],
    [
      -56.70862547525994,
      12.663,
      -7.826066624402071
    ]
  ]
},
{
  "coordinates": [
    [
      -56.70862547525994,
      12.663,
      -7.826066624402071
    ],
    [
      -45.082493612886395,
      12.663,
      -7.891565950450742
    ]
  ]
},
{
  "coordinates": [
    [
      -34.07860553505639,
      12.663,
      -7.53131965933696
    ],
    [
      -45.082493612886395,
      12.663,
      -7.891565950450742
    ]
  ]
},
{
  "lastPoint": "lift",
  "coordinates": [
    [
      -45.082493612886395,
      12.663,
      -7.891565950450742
    ],
    [
      -45.4754899879049,
      12.663,
      -3.8306080644412255
    ],
    [
      -48.291961132318804,
      12.663,
      -4.02710601126997
    ]
  ]
},
{
  "firstPoint": "lift",
  "lastPoint": "lift",
  "coordinates": [
    [
      -48.291961132318804,
      12.663,
      -4.02710601126997
    ],
    [
      -48.291961132318804,
      9.792,
      -4.02710601126997
    ]
  ]
},
{
  "coordinates": [
    [
      -64.09020736161592,
      9.792,
      -3.6531481234170284
    ],
    [
      -56.48591952175745,
      9.792,
      -3.6206511699856305
    ],
    [
      -56.70862547525994,
      9.792,
      -7.826066624402071
    ]
  ]
},
{
  "coordinates": [
    [
      -56.160950846997686,
      9.792,
      -10.022551829189574
    ],
    [
      -56.70862547525994,
      9.792,
      -7.826066624402071
    ]
  ]
},
{
  "coordinates": [
    [
      -56.70862547525994,
      9.792,
      -7.826066624402071
    ],
    [
      -45.082493612886395,
      9.792,
      -7.891565950450742
    ]
  ]
},
{
  "coordinates": [
    [
      -34.07860553505639,
      9.792,
      -7.53131965933696
    ],
    [
      -45.082493612886395,
      9.792,
      -7.891565950450742
    ]
  ]
},
{
  "lastPoint": "lift",
  "coordinates": [
    [
      -45.082493612886395,
      9.792,
      -7.891565950450742
    ],
    [
      -45.4754899879049,
      9.792,
      -3.8306080644412255
    ],
    [
      -48.291961132318804,
      9.792,
      -4.02710601126997
    ]
  ]
},
{
  "firstPoint": "lift",
  "lastPoint": "lift",
  "coordinates": [
    [
      -48.291961132318804,
      9.792,
      -4.02710601126997
    ],
    [
      -48.291961132318804,
      6.923,
      -4.02710601126997
    ]
  ]
},
{
  "coordinates": [
    [
      -64.09020736161592,
      6.923,
      -3.6531481234170284
    ],
    [
      -56.48591952175745,
      6.923,
      -3.6206511699856305
    ],
    [
      -56.70862547525994,
      6.923,
      -7.826066624402071
    ]
  ]
},
{
  "coordinates": [
    [
      -56.160950846997686,
      6.923,
      -10.022551829189574
    ],
    [
      -56.70862547525994,
      6.923,
      -7.826066624402071
    ]
  ]
},
{
  "coordinates": [
    [
      -56.70862547525994,
      6.923,
      -7.826066624402071
    ],
    [
      -45.082493612886395,
      6.923,
      -7.891565950450742
    ]
  ]
},
{
  "coordinates": [
    [
      -34.07860553505639,
      6.923,
      -7.53131965933696
    ],
    [
      -45.082493612886395,
      6.923,
      -7.891565950450742
    ]
  ]
},
{
  "lastPoint": "lift",
  "coordinates": [
    [
      -45.082493612886395,
      6.923,
      -7.891565950450742
    ],
    [
      -45.4754899879049,
      6.923,
      -3.8306080644412255
    ],
    [
      -48.291961132318804,
      6.923,
      -4.02710601126997
    ]
  ]
},
{
  "firstPoint": "lift",
  "lastPoint": "lift",
  "coordinates": [
    [
      -48.291961132318804,
      6.923,
      -4.02710601126997
    ],
    [
      -48.291961132318804,
      3.653,
      -4.02710601126997
    ]
  ]
},
{
  "coordinates": [
    [
      -64.09020736161592,
      3.653,
      -3.6531481234170284
    ],
    [
      -56.48591952175745,
      3.653,
      -3.6206511699856305
    ],
    [
      -56.70862547525994,
      3.653,
      -7.826066624402071
    ]
  ]
},
{
  "coordinates": [
    [
      -63.96022146569374,
      3.653,
      -10.12004271512032
    ],
    [
      -56.160950846997686,
      3.653,
      -10.022551829189574
    ],
    [
      -56.70862547525994,
      3.653,
      -7.826066624402071
    ]
  ]
},
{
  "coordinates": [
    [
      -56.70862547525994,
      3.653,
      -7.826066624402071
    ],
    [
      -45.082493612886395,
      3.653,
      -7.891565950450742
    ]
  ]
},
{
  "coordinates": [
    [
      -34.07860553505639,
      3.653,
      -7.53131965933696
    ],
    [
      -45.082493612886395,
      3.653,
      -7.891565950450742
    ]
  ]
},
{
  "lastPoint": "lift",
  "coordinates": [
    [
      -45.082493612886395,
      3.653,
      -7.891565950450742
    ],
    [
      -45.4754899879049,
      3.653,
      -3.8306080644412255
    ],
    [
      -48.291961132318804,
      3.653,
      -4.02710601126997
    ]
  ]
},
{
  "firstPoint": "lift",
  "lastPoint": "lift",
  "coordinates": [
    [
      -48.291961132318804,
      3.653,
      -4.02710601126997
    ],
    [
      -48.291961132318804,
      0.003,
      -4.02710601126997
    ]
  ]
},
{
  "lastPoint": "door",
  "coordinates": [
    [
      -58.38279476962357,
      0.003,
      -7.425189472126301
    ],
    [
      -47.21218086851779,
      0.003,
      -7.90895617155261
    ]
  ]
},
{
  "firstPoint": "door",
  "coordinates": [
    [
      -47.21218086851779,
      0.003,
      -7.90895617155261
    ],
    [
      -45.26839970591502, 
      0.003,
      -7.689572567227687
    ],
  ]
},
{
  "firstPoint": "lift",
  "coordinates": [
    [
      -48.291961132318804,
      0.003,
      -4.02710601126997
    ],
    [
      -45.5668973896779,
      0.003, 
      -3.681037446619717
    ],
    [
      -45.26839970591502, 
      0.003,
      -7.689572567227687
    ],
  ]
},
{
  "lastPoint": "building-door",
  "coordinates": [
    [
      -45.26839970591502,
       0.003,
      -7.689572567227687
    ],
    [
      -38.06459145109943,
      0.003,
      -7.908956171552611
    ],
    [
      -37.976633502225894,
      0.003,
      -11.251344472125734
    ],
    [
      -34.986075358113666,
      0.003,
      -10.679620133344956
    ],
    [
      -35.258740738147786,
      0.003,
      -15.566931751690339
    ]
  ]
},
{
  "firstPoint": "building-door",
  "lastPoint": "building-door",
  "coordinates": [
      [
          -35.258740738147786,
          0.003,
          -15.566931751690339
      ],
      [
          -18.95546116336888,
          1.0295868898976437,
          -39.41279409327191
      ],
      [
          31.948287030182286,
          2.222436674544298,
          -38.856497848330775
      ],
      [
          32.018846764407186,
          2.6884892749955327,
          -16.753949845074715
      ]
  ]
},
{
  "firstPoint": "building-door",
  "coordinates": [
      [
          32.018846764407186,
          2.6884892749955327,
          -16.753949845074715
      ],
      [
          31.991229157191633,
          3.005,
          -8.063764918584774
      ]
  ]
},
{
  "coordinates": [
      [
          31.991229157191633,
          3.005,
          -8.063764918584774
      ],
      [
          45.5866070363537,
          3.005,
          -6.958694122063267
      ]
  ]
},
{
  "coordinates": [
      [
          45.5866070363537,
          3.005,
          -6.958694122063267
      ],
      [
          46.23330375184795,
          3.005,
          -0.16835690521574342
      ],
      [
          46.16862692575674,
          3.005,
          23.69482083130768
      ]
  ]
},
{
  "coordinates": [
      [
          45.5866070363537,
          3.005,
          -6.958694122063267
      ],
      [
          43.38782959521806,
          3.005,
          0.09032258951035388
      ]
  ]
},
{
  "coordinates": [
      [
          31.991229157191633,
          3.005,
          -8.063764918584774
      ],
      [
          17.90790265268786,
          3.005,
          -6.764684474454921
      ]
  ]
},
{
  "coordinates": [
      [
          17.90790265268786,
          3.005,
          -6.764684474454921
      ],
      [
          17.77856286568892,
          3.005,
          0.15499246298228186
      ],
      [
          17.649223006050477,
          3.005,
          23.500811302290685
      ]
  ]
}
];
